@font-face {
  font-family: Bahij TheSansArabic;
  src: url(/public/assets/font/ArbFONTS-Bahij_TheSansArabic-Plain.ttf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Bahij TheSansArabic";
  font-weight: 600;
}

:root {
  --main-color: #05ac29;
  --second-color: #036918;
  --third-color: #2580c2;
  --text-color: #033c5a;
  --subText-color: #ada3a3;
  --white-color: #fff;
  --bg-color: #131010;
  --main-tran: 0.5s;
}
::selection {
  color: var(--white-color);
  background-color: var(--main-color);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--second-color);
}
::-webkit-scrollbar-thumb {
  background-color: var(--third-color);
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
html {
  scroll-behavior: smooth;
}

body,
html {
  overflow-x: hidden;
}

.container {
  width: 90vw;
  margin: auto;
}
