.contact-details {
  padding-top: 150px;
  margin-bottom: 70px;
}

.contact-details .container {
  position: relative;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-details .form {
  width: 100%;
  max-width: 994px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-details .contact-form {
  background-color:   var(--white-color);
  position: relative;
}
.transafer-icon{
  display: flex;
  align-items: center;
}

.contact-details form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}
.contact-details .input:focus{
    border: 2px solid var(--main-color);
}
.contact-details .title {
  color: var(--white-color);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.contact-details .input-container {
  position: relative;
  margin: 1rem 0;
}

.contact-details .input {
  width: 100%;
  outline: none;
  border: 2px solid #e2d0d0;
  background: none;
  padding: 0.6rem 1.2rem;
  color: var(--bg-color);
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.contact-details textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.contact-details .input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: var(--bg-color);
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.contact-details .input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.contact-details .btn {
  padding: 0.6rem 1.3rem;
  background-color: var(--text-color);
  border: 2px solid #b89393;
  font-size: 20px;
  color: var(--white-color);
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.contact-details .btn:hover {
  background-color: var(--third-color);
  color: #fff;
}

.contact-details .input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.contact-details .input-container span:before,
.contact-details .input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: var(--white-color);
  top: 50%;
  transform: translateY(-50%);
}

.contact-details .input-container span:before {
  left: 50%;
}

.contact-details .input-container span:after {
  right: 50%;
}

.contact-details .input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.contact-details .input-container.focus span:before,
.contact-details .input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-details .contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
  background-image: url("https://cew.org/wp-content/uploads/2020/10/CEW-image_1400x600.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

}
.contact-details .contact-info .info{
 padding: 15px;
 background-color: var(--white-color);
 position: relative;
 margin-top: 130px;
 z-index: 111;
  
}
.contact-info::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.contact-details .contact-info .title {
  color: var(--white-color);
  position: relative;
  z-index: 1221;
}
.contact-details .contact-info .info .information p {
  color: var(--bg-color);
  position: relative;
  z-index: 1221;
}

.contact-details .text {
  color: var(--white-color);
  z-index: 1221;
  position: relative;

  margin: 1.5rem 0 2rem 0;
}
.contact-details .info-icons {
  display: flex;
  align-items: center;
}

.contact-details .info-icons svg {
  margin-top: -1px;
  margin-left: 5px;
  margin-right: 5px;
}
.contact-details .information {
  display: flex;
  color: var(--bg-color);
  margin: 0.7rem 0;
  position: relative;
z-index: 21;
  align-items: center;
  font-size: 0.95rem;
}

.contact-details .icon {
  width: 28px;
  margin-right: 0.7rem;
}


.contact-details .contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #1abc9c;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
  z-index: -11221;
}




@media (max-width: 850px) {
  .contact-details .form {
    grid-template-columns: 1fr;
  }

  .contact-details .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-details .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }




  .contact-details .text {
    margin: 1rem 0 1.5rem 0;
  }


}

@media (max-width: 480px) {
  .contact-details .container {
    padding: 1.5rem;
  }

  .contact-details .contact-info:before {
    display: none;
  }



  .contact-details form,
  .contact-details .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .contact-details .text,
  .contact-details .information{
    font-size: 0.8rem;
  }

  .contact-details .title {
    font-size: 1.15rem;
  }



  .contact-details .icon {
    width: 23px;
  }

  .contact-details .input {
    padding: 0.45rem 1.2rem;
  }

  .contact-details .btn {
    padding: 0.45rem 1.2rem;
  }
}
.Toastify{
  position: relative;
  z-index: 121212;
}