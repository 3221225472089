.footer {
  width: 100%;
  padding: 30px;
  background-color: #172345;
  color: var(--white-color);
  padding: 70px 0;
  width: 100vw;
  margin: auto;
  margin-top: 80px;
  height: 557px;
}

@media (max-width: 1203px) {
  .footer {
    padding: 30px 0;
    height: auto;
  }
}
.footer * {
  color: var(--white-color);
}
.footer .container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.footer .container .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer img {
  width: 220px;
}
.footer .container .top p {
  width: 43%;
}
@media (max-width: 1200px) {
  .footer .container .top,
  .footer .container .bottom {
    flex-direction: column;
  }
  .footer .container .top p {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: center;
  }
}
.footer .container .top .footer-uls {
  display: flex;
  gap: 50px;
}
.footer .container .top .footer-uls .footer-links {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer hr {
  width: 75%;
  margin: auto;
  border: 1px solid var(--main-color);
}
.footer .bottom .footer-icons {
  display: flex;
  justify-content: center;
  gap: 25px;
}
.footer .bottom .footer-icons a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  font-size: 28px;
  border-radius: 12px;
  transition: var(--main-tran);
  border: 1px solid var(--white-color);
}
.footer .bottom .footer-icons a svg {
  transition: var(--main-tran);
  width: 20px;
  -webkit-transition: var(--main-tran);
  -moz-transition: var(--main-tran);
  -ms-transition: var(--main-tran);
  -o-transition: var(--main-tran);
  fill: var(--white-color);
}
.footer .bottom .footer-icons a:nth-child(1) svg {
  width: 14px;
}

.footer .bottom .footer-icons a:hover {
  border-radius: 50%;
  border: none;
  background-color: var(--main-color);
}
.footer .bottom .footer-icons a:hover svg {
  color: var(--footer-color);
}
.footer .container .copy-right {
  text-align: center;
  word-spacing: 3px;
}
@media (max-width: 767px) {
  .footer .container .top {
    flex-direction: column;
    gap: 20px;
  }
}
