.about-us {
  padding: 100px 0 30px;
}

.about-us .container {
  max-width: 1200px;
  margin: 0 auto 20px;
}
@media (max-width: 600px) {
  .about-us img {
    display: none;
  }
}
.fa-download {
  margin: 0 10px;
}

.about-us .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about-us .col-md-6 {
  flex-basis: 50%;
}

.about-us h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.about-us hr {
  margin-bottom: 20px;
  border: none;
  height: 4px;
  border-radius: 10px;
  background-color: var(--third-color);
  position: relative;
  overflow: hidden;
  width: 100px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.about-us hr:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 10px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: hr-ball-animation 2s ease-in-out infinite;
}
.about-us p:last-of-type {
  margin-bottom: 30px;
}

.about-us img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: 10px;
}
@media (min-width: 1100px) {
  .about-us img {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.about-us-header {
  font-size: 23px;
  color: var(--third-color);
}

/* Responsive styles */

@media (max-width: 768px) {
  .about-us .col-md-6 {
    flex-basis: 100%;
  }
  .about-us img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .about-us h1 {
    font-size: 24px;
  }
  .about-us p {
    font-size: 16px;
  }
}

.pyramid {
  text-align: center;
  margin-bottom: 20px;
}

.pyramid div {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  color: var(--white-color);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 0 auto;
  background-color: var(--second-color);
}
.pyramid div:hover {
  background-color: var(--third-color);
}

.pyramid div:nth-child(1) {
  width: 40%;
}

.pyramid div:nth-child(2),
.pyramid div:nth-child(3) {
  width: 45%;
  margin: 10px auto;
  display: inline-block;
}
.about-us span {
  color: var(--second-color);
  font-size: 30px;
  margin-bottom: 20px;
  display: block;
}
.pyramid div:nth-child(4),
.pyramid div:nth-child(5),
.pyramid div:nth-child(6) {
  width: 33.3%;
  margin: 0 auto 10px;
  display: inline-block;
}
@media (max-width: 1225px) {
  .pyramid div:nth-child(2),
  .pyramid div:nth-child(3),
  .pyramid div:nth-child(4),
  .pyramid div:nth-child(5),
  .pyramid div:nth-child(6) {
    width: 45%;
    font-size: 13px;
  }
  .pyramid div:nth-child(1) {
    font-size: 13px;
  }
}
@media (max-width: 700px) {
  .pyramid div:nth-child(2),
  .pyramid div:nth-child(3),
  .pyramid div:nth-child(4),
  .pyramid div:nth-child(5),
  .pyramid div:nth-child(6) {
    width: 45%;
    font-size: 11px;
  }
  .pyramid div:nth-child(1) {
    font-size: 13px;
  }
}
@media (max-width: 365px) {
  .pyramid div {
    width: 100% !important;
    font-size: 13px !important;
  }
}
@media(max-width:290px){
  .tab-Showmore.download-btn{
    width: 150px !important;
  }
}
/****why-us*****/
.why-us {
  margin-bottom: 150px;
}
.why-us .main-heading {
  margin-bottom: 70px;
}
.why-us .container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.why-us .box {
  width: 90vw;
  display: flex;
  flex-direction: row;
}
.why-us .circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.why-us .root1 {
  width: 48%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.why-us .sec1 {
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  margin-bottom: 20px;
}
.why-us .sec1 h1 {
  margin: 0%;
  letter-spacing: 2px;
  color: #42b883;
  font-size: 25px;
}
.why-us .sec1 p {
  font-style: italic;
  font-weight: 500;
  margin: 10px;
}
.why-us .sec1 .circle {
  background-color: var(--third-color);
}
.why-us .circle i {
  color: white;
}
.why-us .sec2 {
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
}
.why-us .sec2 h1 {
  margin: 0%;
  letter-spacing: 2px;
  color: #347474;
  font-size: 25px;
}
.why-us .sec2 p {
  font-style: italic;
  font-weight: 500;
  margin: 10px;
}
.why-us .sec2 .circle {
  background-color: var(--main-color);
}

.why-us .root3 {
  width: 48%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.why-us .sec3 {
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  margin-bottom: 20px;
}
.why-us .sec3 h1 {
  margin: 0%;
  letter-spacing: 2px;
  color: #35495e;
  font-size: 25px;
}
.why-us .sec3 p {
  font-style: italic;
  font-weight: 500;
  margin: 10px;
}
.why-us .sec3 .circle {
  background-color: var(--second-color);
  transition: var(--main-tran);
  -webkit-transition: var(--main-tran);
  -moz-transition: var(--main-tran);
  -ms-transition: var(--main-tran);
  -o-transition: var(--main-tran);
}
.why-us .sec3 .circle:hover,
.why-us .sec2 .circle:hover,
.why-us .sec1 .circle:hover,
.why-us .sec4 .circle:hover {
  background-color: var(--text-color);
}
.why-us .sec4 {
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
}
.why-us .sec4 h1 {
  margin: 0%;
  letter-spacing: 2px;
  color: #ff7e67;
  font-size: 25px;
}
.why-us .sec4 p {
  font-style: italic;
  font-weight: 500;
  margin: 10px;
}
.why-us .sec4 .circle {
  background-color: #3f5586;
}
@media screen and (max-width: 1024px) {
  .why-us .container {
    height: fit-content;
  }
  .why-us .box {
    row-gap: 30px;
    height: fit-content;
  }
  .why-us .root1 {
    width: 48%;
    height: fit-content;
    order: 2;
    row-gap: 30px;
    font-size: 20px;
  }

  .why-us .root3 {
    width: 48%;
    height: fit-content;
    order: 3;
    row-gap: 30px;
    font-size: 20px;
  }
}
@media screen and (max-width: 630px) {
  .why-us .container {
    padding: 20px 0px;
    height: fit-content;
  }
  .why-us .box {
    row-gap: 30px;
    height: fit-content;
  }
  .why-us .root1 {
    width: 48%;
    height: fit-content;
    order: 2;
    row-gap: 30px;
    font-size: 20px;
  }

  .why-us .root3 {
    width: 48%;
    height: fit-content;
    order: 3;
    row-gap: 30px;
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .why-us .box {
    row-gap: 30px;
    height: fit-content;
  }
  .why-us .root1 {
    width: 48%;
  }

  .why-us .root3 {
    width: 48%;
  }
  .why-us .circle{
    width: 90px;
    height: 90px;
    
}
  .why-us .circle i{
    font-size: 35px !important;
  }
  .why-us p, .why-us  h1{
    font-size: 13px !important;
  }
  .sec1{
    margin-top: 5px;
  }
  .sec2{
    margin-top: -20px;
  }
  
}

.download-btn {
  font-size: 14px;
  width: 250px !important;
  line-height: 1.8;
}
