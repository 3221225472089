.parteners-section{
  padding-top: 150px;
}
.Parteners-section .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.our-partner {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 70px;
}

.partner-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.partner-div {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  font-size: 25px;
}

.partner-div img {
  width: 650px;
  max-height: 100%;
  margin: 0 auto 20px;
  display: block;
  max-width: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: var(--main-tran);
  -webkit-transition: var(--main-tran);
  -moz-transition: var(--main-tran);
  -ms-transition: var(--main-tran);
  -o-transition: var(--main-tran);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.partner-div:nth-child(10) img{
  height: 300px !important;
}
@media(max-width:300px){
  .partner-div:nth-child(10) img{
    height: initial !important;
  }
}
.partner-div img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.partner-div a {
  text-decoration: none;
  color: #333;
}

.partner-div a:hover {
  color: #666;
}

/* Responsive layout */

@media (max-width: 768px) {
  .partner-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .partner-div {
    padding: 10px;
  }
  .partner-div img {
    height: 100px;
  }
}
.description {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
}

.description.description-active {
  display: block;
  opacity: 1;
}
