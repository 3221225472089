.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 50px;
  height: 50px;
  right: 25px;
  z-index: 20;
  border-radius: 16px;
  cursor: pointer;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  bottom: -70px;
  transition: 0.6s;
  z-index: 99999;
}
.progress.active {
  bottom: 30px;
}
.progress:hover {
  transform: translateY(-10px);
}
.progress .progress-value {
  height: calc(100% - 6px);
  width: calc(100% - 6px);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--white-color);
  background-color: var(--third-color);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
