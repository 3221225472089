.main-title {
  display: flex;
  font-size: 36px;
  justify-content: center;
  margin: 0 auto;
  padding:  5px;
  position: relative;
  width: fit-content;
  color: var(--second-color);
}
.main-heading{
  margin-bottom: 60px;
}
.main-heading hr {
  border: none;
  height: 4px;
  border-radius: 10px;
  background-color: var(--third-color);
  position: relative;
  overflow: hidden;
  width: 160px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 0 auto 20px;
}

.main-heading  hr:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 10px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: hr-ball-animation 2s ease-in-out infinite;
  -webkit-animation: hr-ball-animation 2s ease-in-out infinite;
}
@media(max-width:500px){
  .main-title{
    font-size: 25px;
    margin-bottom: 20px;
  }
}
@keyframes hr-ball-animation {
  0% {
    left: -10px;
  }
  100% {
    left: 100%;
  }
}