.products-section {
  padding-top: 30px;
  margin-bottom: 170px;
}

.part img {
  width: 100%;
  max-width: 100%;
}
.products-section .swiper-container {
  width: 100%;
  height: 300px; /* adjust the height as needed */
  direction: ltr;

}
.products-section .swiper {
  direction: ltr;
}
.products-section .swiper-slide {
  width: 25%; /* 25% width for each slide */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products-section .swiper-slide img {
  width: 100%;
  object-fit: contain;
  max-width: 100%;
  height: 300px;
  max-height: 100%;
  
}
.products-section .swiper-button-prev, .our-Part .swiper-button-next{
  color: #ffa500;
}

.tab-Showmore{
  display: block;
  background-color: var(--text-color);
  color: var(--white-color);
  text-align: center;
  width: 200px;
  border-radius: 10px;
  padding: 10px;
  margin: 10px auto 80px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.tab-Showmore:hover{
  background-color: var(--second-color);
}