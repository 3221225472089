.top-nav {
  background-color: var(--text-color);
  padding-top: 10px;
}
.top-nav .container {
  display: flex;
  justify-content: space-between;
}
.top-nav .icons {
  display: flex;
}
.top-nav .container .social-details ul {
  display: flex;
}
.top-nav .container .social-details ul li {
  margin: 10px 30px;
  display: flex;
  gap: 10px;
  color: var(--white-color);
}
.top-nav .container .social-details ul li svg {
  margin-top: 4px;
}
.top-nav .icons a {
  padding: 10px;
}
.top-nav .icons a i {
  margin: 0 10px;
  font-size: 25px;
  color: var(--white-color);
}
@media (max-width: 767px) {
  .top-nav .container {
    flex-direction: column;
  }
  .top-nav .icons {
    justify-content: center;
  }
  .top-nav .container .social-details ul {
    justify-content: space-evenly;
  }
  .top-nav .container .social-details ul li {
    margin: 6px;
  }
  .top-nav .container .social-details {
    margin: 0;
  }
  .fa-location-dot {
    margin-top: 2px;
  }
}
@media (max-width: 700px) {
  .top-nav {
    height: 120px;
  }
}
.fa-location-dot {
  margin-top: 4px;
}
.header {
  width: 100vw;
  height: 140px;
  position: fixed;
  top: 70px;
  box-shadow: 0 0 2px var(--second-color);
  background-color: #172345;
  z-index: 112121 !important;
  right: 0;
}
.header .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  color: var(--white-color);
}
.header .container .logo {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header .container .logo .menu-btn {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  transform: scaleX(0.9);
  font-size: 25px;
  border-radius: 12px;
  color: var(--white-color);
  transition: var(--main-tran);
  background-color: var(--main-color);
  margin-top: -15px;
}
.header .container .logo a {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header .container a img {
  width: 180px;
}
.header .container .logo a h1 {
  font-size: 24px;
  font-weight: 500;
  transition: var(--main-tran);
}
.header .container .logo a h1:hover {
  color: var(--main-color);
}
.header .container .ul-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  font-size: 19px;
  font-weight: bold;
}
.header.scrolled {
  top: 0;
}

.header .container .ul-links .li-link a {
  position: relative;
  font-weight: bold;
  transition: var(--main-tran);
  color: var(--white-color);
  text-decoration: none;
}
.header .container .ul-links .li-link a:hover {
  color: var(--main-color);
}
.header .container .ul-links .li-link a.active {
  color: var(--main-color);
}
.header .container .ul-links .li-link a.active::before {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  top: -20px;
  border-radius: 16px;
  transition: var(--main-tran);
  background-color: var(--main-color);
  box-shadow: 0px 0px 5px 0px var(--second-color);
}
.header .container .ul-links .li-link a.active:hover::before {
  transform: scaleX(1.1);
}
.header .container .ul-links .li-link .dropmenu-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header .container .ul-links .li-link .dropmenu-btn span:hover {
  color: var(--main-color);
}
.header .container .ul-links .li-link .dropmenu {
  position: fixed;
  margin-left: 12px;
  padding: 18px;
  border-radius: 15px;
  box-shadow: 0 0 7px #a3b9d233;
  background-color: var(--white-color);
  transition: 0.5s;
  width: 180px;
  z-index: 999;
}
.header .container .ul-links .li-link .dropmenu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.header .container .ul-links .li-link .dropmenu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item {
  text-align: right;
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item a {
  margin-right: 20px;
  transition: var(--main-tran);
  color: var(--third-color);
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item a::before {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--main-color);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item-en {
  text-align: left;
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item-en a::before {
  left: -13px;
}
.header .container .ul-links .li-link .dropmenu hr {
  border: 1px solid #f6e5c3;
  margin: 10px auto;
}

.header svg:before {
  font-size: 30px;
  margin-top: 6px;
}
.header .btns {
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.btns-mobile {
  color: var(--second-color);
}

.header .btns span:hover {
  color: var(--main-color);
}

.header .container svg {
  width: 43px;
  fill: var(--white-color);
  margin-top: 6px;
}
.header .container svg path {
  fill: var(--white-color) !important;
}
@media (max-width: 1200px) {
  .header .container .btns {
    color: var(--third-color);
  }
  .logo-desktop {
    display: none !important;
  }
  .btns-desktop {
    display: none !important;
  }
  .header {
    box-shadow: 0 0 0 !important;
    background-color: inherit;
  }

  .header.scrolled {
    background-color: inherit;
    box-shadow: 0 0 0 !important;
  }
  .header .container .ul-links {
    display: none;
  }
  .header .container .btns {
    margin-top: 0;
  }
  .header .container .logo .menu-btn {
    display: flex !important;
  }
}

.mob-menu {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 999;
  width: 250px; /* adjust the width to your liking */
  height: 100vh;
  padding: 15px;
  border-radius: 0;
  background-color: var(--white-color);
  box-shadow: 0 0 7px #a3b9d291;
  transition: var(--main-tran);
}

.mob-menu.active {
  left: 0;
  z-index: 1221;
}

.mob-menu .mob-links {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding-top: 53px;
  padding-right: 13px;
}

.mob-menu .mob-links .mob-link a {
  color: var(--third-color);
}
.top-nav .container .social-details {
  margin-bottom: 20px;
}

@media (min-width: 1201px) {
  .mob-menu {
    display: none;
  }
  .logo-mobile{
    display: none !important;
  }
}
@media (max-width: 600px) {
  .header .container .logo .menu-btn {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .header {
    top: 120px;
  }
}
@media (max-width: 478px) {
  .top-nav {
    display: none;
  }
  .header {
    top: 0;
  }
}
.social-details ul li a {
  color: var(--white-color);
}

.company-number {
  width: 115px;
}
@media(max-width:300px){
  .mob-menu{
    display: none;
  }
}