/* Hero Section */
.hero-section {
  position: relative;
  top: 0;
}

.hero-section .swiper-slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.hero-section .swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.6);
}

.content {
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white-color);
  font-size: 26px;
  width: 60%;
  line-height: 2;
}

.hero-section .swiper-slide img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}

:dir(rtl) .content {
  text-align: right;
}
@media (max-width: 400px) {
  .hero-section .content p {
    text-align: center;
  }
}

.nav-btn {
  display: flex;
  position: absolute;
  top: 90%;
  transform: translateY(-90%);
  z-index: 20;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  transition: background 0.5s ease;
}

.nav-btn:hover {
  background-color: var(--main-color);
}

.nav-btn.custom-prev-button {
  left: 2em;
  top: 50%;
}

.nav-btn.custom-next-button {
  right: 2em;
  top: 50%;
}

.hero-links {
  margin: 30px 0;
}

.hero-links a {
  display: inline-block;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  color: var(--white-color);
}

.hero-links a:first-child {
  margin: 0 10px;
  background-color: var(--second-color);
  padding: 10px 30px;
}

.hero-links a:first-child:hover {
  background-color: var(--main-color);
}

.hero-links a:nth-child(2) {
  background-color: var(--third-color);
  padding: 10px 20px;
}

.hero-links a:nth-child(2):hover {
  background-color: var(--main-color);
}

@media (max-width: 1200px) {
  .content {
    font-size: 22px;
    width: 70%;
  }
}

@media (max-width: 900px) {
  .content {
    font-size: 18px;
    width: 90%;
  }
  .hero-links a {
    font-size: 18px;
  }
  .hero-links {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .content {
    font-size: 16px;
    width: 90%;
  }
  .hero-links a {
    font-size: 16px;
  }
  .nav-btn {
    top: 80%;
  }
}

@media (max-width: 400px) {
  .content {
    font-size: 14px;
    width: 100%;
  }
  .hero-links a {
    font-size: 14px;
  }
  .nav-btn {
    top: 70%;
  }
  .hero-links {
    margin: 20px 0;
  }
}

/* Counter */
.counter-section {
  transition: all 0.5s ease-in-out;
}
.counter-section .toggler{
  padding: 9px 30px;
  border-radius: 6px;
  background-color: #a4a087;
  border: none;
  color: var(--white-color);
  outline: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  margin-bottom: 20px;
}
.counter-section.animate {
  opacity: 1;
}

.counter-section.animate{
  opacity: 0;
}
.wrapper {
  display: flex;
  justify-content: center;

  text-align: center;
  margin-bottom: 60px;
}

.wrapper .wrapper-container {
  width: 30%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  color: var(--white-color);
  z-index: 1;
}

.wrapper-container span {
  font-size: 26px;
}
@media (max-width: 900px) {
  .wrapper-container span {
    font-size: 15px;
  }
}

.wrapper-container:nth-child(1),
.wrapper-container:nth-child(3) {
  background-color: #06a3da;
}

.wrapper-container:nth-child(2) {
  background-color: #ffffff;
  color: var(--bg-color);
}

/* Responsive Fixes for Counter */
@media (max-width: 900px) {
  .wrapper .wrapper-container {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .wrapper .wrapper-container {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .wrapper .wrapper-container {
    width: 100%;
    margin: 20px 0;
  }
}
/****home-partenters********/
.our-Part{
  padding-bottom: 150px;
}
.part-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  gap: 20px;
  margin: 0 auto;
}
.part-grid .part {
  margin-bottom: 10px;
}
.part img {
  width: 100%;
  max-width: 100%;
}
.our-Part .swiper-container {
  width: 100%;
  height: 300px; /* adjust the height as needed */
  direction: ltr;

}
.our-Part .swiper {
  direction: ltr;
}
.our-Part .swiper-slide {
  width: 25%; /* 25% width for each slide */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-Part .swiper-slide img {
  width: 100%;
  object-fit: contain;
  max-width: 100%;
  height: 300px;
  max-height: 100%;
  
}
.our-Part .swiper-button-prev, .our-Part .swiper-button-next{
  color: #ffa500;
}

@media(min-width:1024px){
  .why-us{
    margin-bottom: 260px !important;
  }
}
@media(max-width:290px){
  .tabs a{
    padding: 10px !important;
    font-size: 16px !important;
  }
}